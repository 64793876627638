import Axios from 'axios';
import { AddEstimatesData, Estimate } from './estimates.interface';
import download from 'downloadjs';
import { z } from 'zod';

export const EstimateStatus = z.enum([
  'SCOPING',
  'ESTIMATING',
  'COMPLETED',
  'APPROVAL_PENDING',
  'APPROVED',
  'REJECTED',
]);

export type EstimateStatusType = z.infer<typeof EstimateStatus>;

export async function addEstimate(addEstimatesData: AddEstimatesData) {
  const res = await Axios.post('/api/v1/estimates', addEstimatesData);
  return res.data;
}

export async function updateEstimate(estimateId: string, estimate: Estimate) {
  const res = await Axios.put(`/api/v1/estimates/${estimateId}`, estimate);
  return res.data;
}

export async function listJobEstimates(jobId: string) {
  const res = await Axios.get(`/api/v1/estimates/job/${jobId}`);
  return res.data;
}

export async function getEstimateById(jobId: string, estimateId: string) {
  const res = await Axios.get(`/api/v1/estimates/job/${jobId}/${estimateId}`);

  return res.data as Estimate;
}

export async function deleteEstimateById(estimateId: string) {
  const res = await Axios.delete(`/api/v1/estimates/${estimateId}`);

  return res.data;
}

export async function downloadEstimateReport(
  jobId: string,
  estimateId: string
) {
  const res = await Axios.get(
    `/api/v1/estimates/job/${jobId}/estimate/${estimateId}/estimate-report`,
    {
      responseType: 'arraybuffer',
    }
  );

  const filename = `estimate-report-${estimateId}.pdf`;
  const mime = res.headers['Content-Type'];
  download(res.data, filename, mime);
}

export async function generateAndSaveEstimate(
  jobId: string,
  estimateId: string,
  letterheadId?: string,
  showJobDetailsHeader?: boolean
) {
  const searchParams = new URLSearchParams(
    `letterheadId=${letterheadId}&showJobDetailsHeader=${showJobDetailsHeader}`
  ).toString();

  const res = await Axios.post(
    `/api/v1/estimates/job/${jobId}/estimate/${estimateId}/estimate-report/generate?${searchParams}`
  );

  return res.data;
}

export async function copyEstimate(
  estimateId: string,
  estimateName: string,
  jobId: string
) {
  const res = await Axios.post(`/api/v1/estimates/${estimateId}/copy`, {
    estimateId,
    estimateName,
    jobId,
  });

  return res.data;
}

export async function approveEstimate({
  jobId,
  estimateId,
}: {
  jobId: string;
  estimateId: string;
}) {
  const res = await Axios.put(
    `/api/v1/estimates/job/${jobId}/${estimateId}/approve`
  );

  return res.data;
}

export async function rejectEstimate({
  jobId,
  estimateId,
}: {
  jobId: string;
  estimateId: string;
}) {
  const res = await Axios.put(
    `/api/v1/estimates/job/${jobId}/${estimateId}/reject`
  );

  return res.data;
}
