import React, { useState, useEffect } from 'react';
import {
  Dialog,
  makeStyles,
  DialogContent,
  FormGroup,
  TextField,
  Button,
  Divider,
  Box,
  DialogActions,
  CircularProgress,
  Typography,
  Switch,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  LinearProgress,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { User } from 'api/userApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ApplyRecurringChangesFor,
  createNewScheduledTask,
  updateScheduledTask,
} from 'api/calendarApi';
import { useSnackbar } from 'notistack';
import { Job, ScheduledTask, ScheduledTaskAttachmentDetails } from 'api/jobApi';
import { useForm } from 'react-hook-form';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DialogTitle from 'components/DialogOverrides/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ScheduleIcon from '@material-ui/icons/Schedule';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import combineDateAndTime from 'util/combineDateAndTime';
import AvailableUserAutocomplete from './AvailableUserAutocomplete';
import JobListAutocomplete from 'components/Jobs/JobListAutocomplete';
import { JobAPI } from '../../api';
import { DropzoneArea } from 'material-ui-dropzone';
import { ACCEPTED_FILE_TYPES } from 'components/Jobs/Uploads/acceptedFileTypes';
import {
  getFileSize,
  isSizeExceeded,
  MAX_TOTAL_SIZE_TASK_ATTACHMENTS_IN_BYTES,
  MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB,
} from 'util/checkFileSizeLimit';
import FileChip from 'components/Calendar/FileChip';
import { Alert, Grid } from '@mui/material';
import RecurringTasksOptions, {
  RecurringEndOn,
  RecurringFrequency,
  RecurringMonthOption,
  RecurringOptions,
  weekDays,
} from './RecurringTasksOptions';
import { z } from 'zod';
import useOrgStorage from 'util/useOrgStorage';
import { generateSampleDatesForByWeekDay } from 'util/timeConversions';

interface Props {
  /**
   * If jobId is not supplied here, the form will allow the option to
   * create a generic (non-job related) task. If provided, that
   * option will be hidden.
   */
  jobId?: string;
  handleClose: () => void;
  editTaskDefaultValues?: ScheduledTask;

  /** Force form to go into create mode instead of updating when
   * default values are provided. */
  forceCreateMode?: boolean;
  duplicateTask?: boolean;
}

const useStyles = makeStyles((theme) => ({
  mTop: { marginTop: theme.spacing(3) },
  cancelBtn: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
  },
}));

export interface FormData {
  name: string;
  description?: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  assignedUsers: User[];
  selectedJob?: Pick<Job, 'id' | 'siteAddress' | 'clientId' | 'client'>;
  isRecurring?: boolean;
  interval?: number;
  freq?: RecurringFrequency;
  monthOption?: RecurringMonthOption;
  byWeekDay?: string[];
  endOption?: RecurringEndOn;
  count?: number;
  endsOn?: Date;
}

const NonRecurringSchema = z.object({
  isRecurring: z.literal(false),
});

const RecurringSchema = z
  .object({
    isRecurring: z.literal(true),
    interval: z.number().refine((val) => val > 0, {
      message: 'Interval should be greater than 0',
    }),
    freq: z.enum(['day', 'week', 'month', 'year']),
    monthOption: z.enum(['byDateNumber', 'byMonthDay']).optional(),
    byWeekDay: z.array(z.string()).optional(),
    endOption: z.enum(['on', 'after', 'never']),
    count: z.number().optional(),
    endsOn: z.date().optional(),
    sampleByWeekDates: z.array(z.date()).optional(),
    taskTimezone: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data.endOption === 'after' &&
      (data.count === undefined || data.count <= 1)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Occurrence should be greater than 1. `,
      });
    }

    if (
      data.endOption === 'on' &&
      (data.endsOn === undefined || !data.endsOn)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Ends on date is required.`,
      });
    }

    if (
      data.freq === 'week' &&
      (data.byWeekDay === undefined || data.byWeekDay?.length === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `At least one day should selected.`,
      });
    }
  });

export const RecurrenceSchema = NonRecurringSchema.or(RecurringSchema);

const AddOrEditTask = ({
  jobId,
  handleClose,
  editTaskDefaultValues: editValues,
  forceCreateMode = false,
  duplicateTask = false,
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isEditing] = useState(Boolean(editValues?.id));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasJob, setHasJob] = useState(Boolean(jobId));
  const [files, setFiles] = useState<File[]>([]);
  const [attachmentArray, setAttachmentArray] = useState<
    ScheduledTaskAttachmentDetails[] | File[]
  >(editValues?.attachments);
  const [recurringDialogOpen, setRecurringDialogOpen] = useState(false);
  const [recurringDataChanged, setRecurringDataChanged] = useState(false);
  const [applyRecurringChangesFor, setApplyRecurringChangesFor] = useState(
    ApplyRecurringChangesFor.ALL
  );
  const [recurringValidate, setRecurringValidate] = useState(true);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isFreePlanExceeded } = useOrgStorage();

  const { isFetching, data: jobs } = useQuery(['list-job-names'], () =>
    JobAPI.listAllJobSummaries()
  );

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    unregister,
    getValues,
    control,
    reset,
    setError,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: editValues
      ? {
          name: editValues.name,
          description: editValues?.description ?? '',
          date: parseISO(editValues.start),
          startTime: parseISO(editValues.start),
          endTime: parseISO(editValues.end),
          assignedUsers:
            editValues.taskAssignments?.map((t) => t.user) || undefined,
        }
      : {},
  });

  const watchDate = watch('date');
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');
  const selectedJob = watch('selectedJob');
  const today = new Date();
  today.setHours(0);
  const taskIsinThePast = watchDate && isBefore(watchDate, today);

  const validateEndTime = (value: Date) => {
    if (!value) return false;
    const { date, startTime, endTime } = getValues();
    const start = combineDateAndTime(date, startTime);
    const end = combineDateAndTime(date, endTime);
    return isBefore(start, end)
      ? true
      : 'Invalid time: Must be after the starting time';
  };

  useEffect(() => {
    register({ name: 'date' }, { required: 'Required' });
    register({ name: 'startTime' }, { required: 'Required' });
    register(
      { name: 'endTime', type: 'custom' },
      {
        required: 'Required',
        validate: validateEndTime,
      }
    );
    setValue(
      'date',
      editValues?.start ? parseISO(editValues.start) : new Date()
    );
    return () => {
      unregister('date');
      unregister('startTime');
      unregister('endTime');
    };
    // eslint-disable-next-line
  }, [reset, register, unregister, setValue]);

  const { isFetching: isJobFetching, data: jobData } = useQuery(
    ['job-by-id', selectedJob?.id],
    () => JobAPI.getJobById(selectedJob?.id),
    { enabled: Boolean(selectedJob?.id) }
  );

  const { data: defaultSelectedJobData } = useQuery(
    ['job-by-id', jobId],
    () => JobAPI.getJobById(jobId),
    { enabled: Boolean(jobId) }
  );

  useEffect(() => {
    if (defaultSelectedJobData) {
      register({ name: 'selectedJob' });
      setValue('selectedJob', defaultSelectedJobData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedJobData]);

  useEffect(() => {
    setValue('clientName', selectedJob?.client?.companyName);
    if (jobData?.contactName) {
      setValue(
        'siteContact',
        `${jobData?.contactName || ''} ${jobData?.contactNumber || ''}`
      );
    }
    if (jobData?.supervisor) {
      setValue('supervisor', jobData?.supervisor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob, jobData]);

  const onMutationSuccess = async () => {
    await queryClient.invalidateQueries(['calendar-events']);
    await queryClient.invalidateQueries(['scheduled-tasks']);
    await queryClient.invalidateQueries(['org-storage-usage']);
    await queryClient.invalidateQueries(['estimate-billing']);
    setLoading(false);
    enqueueSnackbar('Scheduled task updated successfully', {
      variant: 'success',
    });
    handleClose();
  };

  const { mutate: mutateUpdate } = useMutation(updateScheduledTask, {
    onSuccess: onMutationSuccess,
    onError: (err) => {
      setLoading(false);
      console.error(err);
      enqueueSnackbar('Failed to update scheduled task', {
        variant: 'error',
      });
    },
  });

  const { mutate: mutateCreate } = useMutation(createNewScheduledTask, {
    onSuccess: onMutationSuccess,
    onError: (err) => {
      console.error(err);
      enqueueSnackbar('Failed to update scheduled task', {
        variant: 'error',
      });
    },
  });

  const getFreqByText = (text: string) => {
    switch (text) {
      case 'DAILY':
        return RecurringFrequency.DAILY;
      case 'WEEKLY':
        return RecurringFrequency.WEEKLY;
      case 'MONTHLY':
        return RecurringFrequency.MONTHLY;
      case 'YEARLY':
        return RecurringFrequency.YEARLY;
      default:
        return RecurringFrequency.WEEKLY;
    }
  };

  const [recurringData, setRecurringData] = useState<RecurringOptions>({
    isRecurring: editValues?.recurringData && isEditing,
    interval:
      isEditing && editValues?.recurringData?.interval
        ? editValues?.recurringData?.interval
        : 1,
    freq:
      isEditing && editValues?.recurringData?.freq
        ? getFreqByText(editValues?.recurringData?.freq)
        : RecurringFrequency.WEEKLY,
    monthOption:
      isEditing && editValues?.recurringData?.monthOption
        ? JSON.parse(editValues?.recurringData?.monthOption)?.option
        : RecurringMonthOption.DATENUMBER,
    byWeekDay:
      isEditing &&
      editValues?.recurringData?.byWeekDay &&
      editValues?.recurringData?.byWeekDay?.length > 0 &&
      getFreqByText(editValues?.recurringData?.freq) ===
        RecurringFrequency.WEEKLY
        ? editValues?.recurringData?.byWeekDay
        : watchDate && weekDays[watchDate?.getDay()]
        ? [weekDays[watchDate?.getDay()]]
        : ['Monday'],
    endOption:
      isEditing && editValues?.recurringData?.endOption
        ? editValues?.recurringData?.endOption
        : RecurringEndOn.NEVER,
    count:
      isEditing && editValues?.recurringData?.count
        ? editValues?.recurringData?.count
        : 12,
    endsOn:
      isEditing && editValues?.recurringData?.endsOn
        ? editValues?.recurringData?.endsOn
        : watchDate,
    sampleByWeekDates: editValues?.recurringData?.sampleByWeekDates,
  });

  const submitTask = async (formData: FormData) => {
    try {
      // Date has to be merged with the start/end times.
      const {
        date,
        startTime,
        endTime,
        isRecurring,
        interval,
        freq,
        monthOption,
        byWeekDay,
        endOption,
        count,
        endsOn,
      } = formData;
      const recurringObj = {
        isRecurring,
        interval,
        freq,
        monthOption,
        byWeekDay,
        endOption,
        count,
        endsOn: endsOn ? new Date(endsOn) : undefined,
        sampleByWeekDates:
          freq === 'week'
            ? generateSampleDatesForByWeekDay(
                byWeekDay,
                combineDateAndTime(date, startTime)
              )
            : undefined,
        taskTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      RecurrenceSchema.safeParse(recurringObj);
      const data: ScheduledTask = {
        jobId: hasJob ? selectedJob?.id : undefined,
        name: formData.name,
        description: formData.description,
        start: combineDateAndTime(date, startTime).toISOString(),
        end: combineDateAndTime(date, endTime).toISOString(),
        assignedUserIds: formData.assignedUsers.map((u) => u.id),
        attachments: files ?? undefined,
        recurringData: recurringObj,
      };

      setLoading(true);
      if (isEditing && forceCreateMode === false) {
        const attachmentsToRemove = editValues?.attachments.filter(
          (sourceItem: ScheduledTaskAttachmentDetails) => {
            return !attachmentArray.some(
              (removeItem) => removeItem.id === sourceItem.id
            );
          }
        );
        if (applyRecurringChangesFor === ApplyRecurringChangesFor.ALL) {
          await mutateUpdate({
            taskId: editValues.id,
            data,
            attachmentsToRemove,
          });
        } else {
          await mutateUpdate({
            taskId: editValues.id,
            data,
            attachmentsToRemove,
            applyRecurringChangesFor,
          });
        }
      } else {
        if (duplicateTask) {
          const attachmentsToRemove = editValues?.attachments.filter(
            (sourceItem: ScheduledTaskAttachmentDetails) => {
              return !attachmentArray.some(
                (removeItem) => removeItem.id === sourceItem.id
              );
            }
          );
          if (editValues?.isRecurring) {
            await mutateUpdate({
              taskId: editValues.id,
              data,
              attachmentsToRemove,
              duplicateTask,
              isBaseTaskRecurring: true,
            });
          } else {
            await mutateUpdate({
              taskId: editValues.id,
              data,
              attachmentsToRemove,
              duplicateTask,
            });
          }
        } else {
          await mutateCreate({ data });
        }
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const userType = hasJob ? 'Technicians' : 'Users';

  const removeItemById = (idToRemove) => {
    const updatedItems = attachmentArray.filter(
      (item: ScheduledTaskAttachmentDetails) => item.id !== idToRemove
    );
    setAttachmentArray(updatedItems);
  };

  const RecurringSaveDialog = React.memo(() => {
    return (
      <Dialog
        open={recurringDialogOpen}
        onClose={() => setRecurringDialogOpen(false)}
        fullWidth
        maxWidth="xs">
        <DialogTitle onClose={() => setRecurringDialogOpen(false)}>
          Apply changes for
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="recurringApplyFor"
              value={applyRecurringChangesFor}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                setApplyRecurringChangesFor(
                  (event.target as HTMLInputElement)
                    .value as ApplyRecurringChangesFor
                );
              }}>
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label="This task only"
              />
              <FormControlLabel
                value="thisAndFuture"
                control={<Radio />}
                label="This and following tasks"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="For all tasks"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button onClick={() => setRecurringDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setRecurringDialogOpen(false);
              setApplyRecurringChangesFor(ApplyRecurringChangesFor.ALL);
              handleSubmit(submitTask)(e);
            }}
            autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      disableBackdropClick
      fullWidth
      scroll="paper"
      fullScreen={isMobile}
      maxWidth="sm">
      <RecurringSaveDialog />
      <DialogTitle onClose={handleClose}>
        {isEditing && !forceCreateMode
          ? 'Edit scheduled task'
          : 'Schedule a new task'}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            name="name"
            label="Task name*"
            fullWidth
            disabled={loading}
            variant="outlined"
            autoComplete="disabled"
            inputProps={{ autoComplete: 'off' }}
            inputRef={register({
              required: 'A task name is required',
              maxLength: {
                value: 200,
                message: 'Must be less than 200 characters',
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <FormControlLabel
            control={
              <Switch
                checked={hasJob}
                onChange={(event) => setHasJob(event.target.checked)}
                name="hasJob"
              />
            }
            label={'Associated with a job'}
          />

          {hasJob &&
            (isFetching ? (
              <LinearProgress color={'primary'} />
            ) : (
              <JobListAutocomplete
                className={classes.mTop}
                control={control}
                name="selectedJob"
                rules={{ required: 'Please select a job' }}
                errors={errors}
                label="Select Job"
                jobs={jobs}
                isFetching={isFetching}
                defaultValue={defaultSelectedJobData}
              />
            ))}

          {hasJob && watch('selectedJob') && (
            <>
              {isJobFetching && <LinearProgress color={'primary'} />}
              {!isJobFetching && (
                <Grid container mx={1}>
                  <Grid item my={2} xs={6}>
                    <Typography variant="body2">Job Number</Typography>
                    <Typography variant="body1">
                      <strong>
                        {jobData?.identifier
                          ? `${jobData.identifier} - ${jobData.jobNo}`
                          : '-'}
                      </strong>
                    </Typography>
                  </Grid>
                  {!jobData?.isPrivateJob && (
                    <Grid item my={2} xs={6}>
                      <Typography variant="body2">Client Name</Typography>
                      <Typography variant="body1">
                        <strong>{jobData?.client?.companyName}</strong>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item my={2} xs={6}>
                    <Typography variant="body2">Site Contact</Typography>
                    <Typography variant="body1">
                      <strong>{jobData?.contactName}</strong>
                    </Typography>
                  </Grid>

                  {jobData.supervisor && (
                    <Grid item my={2} xs={6}>
                      <Typography variant="body2">Supervisor</Typography>
                      <Typography variant="body1">
                        <strong>{`${jobData?.supervisor?.firstName} ${jobData?.supervisor?.lastName}`}</strong>
                      </Typography>
                    </Grid>
                  )}

                  {jobData?.projectManager && (
                    <Grid item my={2} xs={6}>
                      <Typography variant="body2">
                        Project Coordinator
                      </Typography>
                      <Typography variant="body1">
                        <strong>{`${jobData?.projectManager?.firstName} ${jobData?.projectManager?.lastName}`}</strong>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}

          <Divider className={classes.mTop} />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              fullWidth
              className={classes.mTop}
              orientation="landscape"
              variant="static"
              error={Boolean(errors.date)}
              helperText={errors?.date?.message}
              openTo="date"
              value={watchDate}
              defaultValue={
                editValues?.start ? parseISO(editValues?.start) : new Date()
              }
              onChange={(value) => setValue('date', value)}
            />
            <Divider />
            <Box display="flex" justifyContent="space-between" my={2}>
              <KeyboardTimePicker
                name="startTime"
                style={{ flexGrow: 1, marginRight: '1rem' }}
                label="From"
                placeholder="09:00 AM"
                mask="__:__ _M"
                inputVariant="outlined"
                value={watchStartTime ?? null}
                onChange={(value) => {
                  setValue('startTime', value);
                  const start = combineDateAndTime(watchDate, value);
                  const end = combineDateAndTime(watchDate, watchEndTime);
                  if (start) {
                    clearErrors('startTime');
                  }
                  if (isBefore(start, end) || !end) {
                    clearErrors('endTime');
                  } else {
                    setError('endTime', {
                      type: 'manual',
                      message: 'Invalid time: Must be after the starting time',
                    });
                  }
                }}
                error={Boolean(errors.startTime)}
                helperText={errors?.startTime?.message}
                keyboardIcon={<ScheduleIcon />}
              />
              <KeyboardTimePicker
                style={{ flexGrow: 1 }}
                name="endTime"
                label="To"
                placeholder="09:00 AM"
                mask="__:__ _M"
                inputVariant="outlined"
                value={watchEndTime ?? null}
                onChange={(value) => {
                  setValue('endTime', value);
                  const start = combineDateAndTime(watchDate, watchStartTime);
                  const end = combineDateAndTime(watchDate, value);
                  if (isBefore(start, end)) {
                    clearErrors('endTime');
                  } else if (!start) {
                    clearErrors('endTime');
                  } else {
                    setError('endTime', {
                      type: 'manual',
                      message: 'Invalid time: Must be after the starting time',
                    });
                  }
                }}
                error={Boolean(errors.endTime)}
                helperText={errors?.endTime?.message}
                keyboardIcon={<ScheduleIcon />}
              />
            </Box>
          </MuiPickersUtilsProvider>
          {taskIsinThePast && (
            <Alert severity="warning" style={{ marginBottom: 15 }}>
              Task is being scheduled in the past
            </Alert>
          )}
          <RecurringTasksOptions
            formValues={getValues()}
            setRecurringDataObj={setRecurringData}
            recurringDataObj={recurringData}
            isEditing={isEditing}
            setIsRecurringDataChanged={setRecurringDataChanged}
            control={control}
            setValue={setValue}
            setRecurringValidate={setRecurringValidate}
          />
          <Typography variant="h5" gutterBottom>
            Assign {userType}
          </Typography>
          <Divider />
          <AvailableUserAutocomplete
            startDateTime={combineDateAndTime(watchDate, watchStartTime)}
            endDateTime={combineDateAndTime(watchDate, watchEndTime)}
            role={hasJob ? 'Technician' : null}
            control={control}
            className={classes.mTop}
            name="assignedUsers"
            rules={{
              required: 'At least one user must be assigned to a task',
            }}
            errors={errors}
            label={
              isEditing && !forceCreateMode
                ? `Assigned ${userType}`
                : `Available ${userType}`
            }
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            multiline
            rows={10}
            disabled={loading}
            variant="outlined"
            autoComplete="disabled"
            className={classes.mTop}
            inputRef={register({
              maxLength: {
                value: 65000,
                message: 'Must be less than 65000 characters',
              },
            })}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <Box my={4}>
            <Typography variant="h5" gutterBottom>
              Attachments
            </Typography>
            <Divider />
            {attachmentArray?.length !== 0 && (
              <>
                {attachmentArray?.map((item) => (
                  <FileChip
                    fileData={item}
                    key={item.id}
                    handleClose={() => removeItemById(item.id)}
                  />
                ))}
              </>
            )}
            {isFreePlanExceeded ? (
              <Alert severity="warning" style={{ margin: '10px 0' }}>
                Your organization has reached the storage limit allowed in the
                free trial. Please upgrade your plan to upload more attachments.
                You can still schedule tasks/events without attachments.
              </Alert>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{
                    color: isSizeExceeded(
                      files,
                      MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB
                    )
                      ? 'red'
                      : 'gray',
                    marginTop: '1.2em',
                    marginBottom: '0.5em',
                  }}>
                  {`You can upload up to 10 files at once within a maximum size of ${MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB}MB. (${getFileSize(
                    files
                  )}MB of ${MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB}MB)`}
                </Typography>
                <DropzoneArea
                  onChange={(files: File[]) => setFiles(files)}
                  showAlerts={['error', 'info']}
                  acceptedFiles={ACCEPTED_FILE_TYPES}
                  maxFileSize={MAX_TOTAL_SIZE_TASK_ATTACHMENTS_IN_BYTES}
                  filesLimit={10}
                  previewGridProps={{
                    container: { justify: 'center' },
                  }}
                  useChipsForPreview
                  previewChipProps={{ size: 'small' }}
                  dropzoneText="Drag and drop files here or click to select attachments"
                />
              </>
            )}
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Box mr={3} mt={1} mb={2}>
          <Button
            onClick={handleClose}
            className={classes.cancelBtn}
            disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              loading ||
              !recurringValidate ||
              isSizeExceeded(files, MAX_TOTAL_SIZE_TASK_ATTACHMENTS_MB)
            }
            endIcon={
              loading ? <CircularProgress size={15} /> : <ArrowForwardIcon />
            }
            onClick={(e) => {
              if (
                isEditing &&
                recurringData?.isRecurring &&
                editValues.isRecurring &&
                !recurringDataChanged &&
                !duplicateTask
              ) {
                setRecurringDialogOpen(true);
              } else {
                handleSubmit(submitTask)(e);
              }
            }}>
            {loading
              ? 'Please wait...'
              : isEditing && !forceCreateMode
              ? 'Update task'
              : 'Schedule task'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrEditTask;
