import React from 'react';
import { Role } from 'models/Role';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactsIcon from '@material-ui/icons/Contacts';
import TodayIcon from '@material-ui/icons/Today';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import CategoryIcon from '@material-ui/icons/Category';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TitleIcon from '@mui/icons-material/Title';
import UsageIcon from '@material-ui/icons/DataUsage';
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export interface SidebarItem {
  title: string;
  icon: JSX.Element;
  open?: boolean;
  href?: string;
  restrictTo?: Role[];
  nestedItems?: {
    title: string;
    href: string;
    icon: JSX.Element;
  }[];
}

export const sidebarItems: Array<SidebarItem & { 'data-cy'?: string }> = [
  {
    title: 'Dashboard',
    href: '/',
    icon: <DashboardIcon data-cy={'dashboard-icon'} />,
    restrictTo: ['Administrator', 'ProjectManager', 'Supervisor', 'Technician'],
    // Add the data-cy attribute here
  },
  {
    title: 'Administration',
    icon: <SettingsIcon data-cy={'setting-icon'} />,
    href: '/administration',
    restrictTo: ['Administrator'],
    open: false,
    nestedItems: [
      {
        title: 'Organization',
        href: '/administration/organization',
        icon: <BusinessIcon data-cy={'business-icon'} />,
      },
      {
        title: 'Users',
        href: '/administration/users',
        icon: <PeopleIcon data-cy={'user-icon'} />,
      },
      {
        title: 'Form Templates',
        href: '/administration/form-templates',
        icon: <ListAltIcon data-cy={'form-icon'} />,
      },
      {
        title: 'Letterheads',
        href: '/administration/letterheads',
        icon: <TitleIcon data-cy={'letterhead-icon'} />,
      },
      {
        title: 'Moisture Meters',
        href: '/administration/moisture-meters',
        icon: <SpeedIcon data-cy={'letterhead-icon'} />,
      },
      {
        title: 'Management Reports',
        href: '/administration/management-reports',
        icon: <AssessmentIcon data-cy={'assessment-icon'} />,
      },
      {
        title: 'Usage & Billing',
        href: '/administration/billing',
        icon: <UsageIcon data-cy={'usage-icon'} />,
      },
      {
        title: 'Service Catalog',
        href: '/administration/service-catalog',
        icon: <RequestQuoteIcon data-cy={'service-catalog-icon'} />,
      },
    ],
  },
  {
    title: 'Clients',
    href: '/clients',
    icon: <ContactsIcon data-cy={'clients-icon'} />,
    restrictTo: ['Administrator', 'ProjectManager', 'Supervisor'],
  },
  {
    title: 'Jobs',
    href: '/jobs',
    icon: <AssignmentIcon data-cy={'jobs-icon'} />,
    restrictTo: ['Administrator', 'ProjectManager', 'Supervisor', 'Technician'],
  },
  {
    title: 'Equipment',
    href: '/equipment',
    icon: <CategoryIcon data-cy={'equipment-icon'} />,
    restrictTo: ['Administrator'],
  },

  {
    title: 'Calendar',
    href: '/calendar',
    icon: <TodayIcon data-cy={'calendar-icon'} />,
    restrictTo: ['Administrator', 'ProjectManager', 'Supervisor', 'Technician'],
  },
];
